<template>
    <div id="wxLogin">
        <div v-if="type == -1" class="form">
			<img :src="$store.state.tenantInfo.logo | fullPath"/>
			<p style="font-size: 18px;">商家端</p>
			<input type="tel" placeholder="用户名" v-model="formData.username" />
			<input type="password" placeholder="密码" v-model="formData.password" />
			<div class="verification">
				<input type="text" placeholder="验证码" v-model="formData.verifyCode" />
				<div @click="reflashCode" id="imgcode">
					<img :src="imgUrl"/>
				</div>
			</div>
			<input @click="login" type="button" value="登录" />
		</div>
		<div v-else-if="type == 1" class="form">
			<img :src="$store.state.tenantInfo.logo | fullPath"/>
			<h3>请点击下方按钮授权</h3>
			<p>授权后，你将开通微信提现功能，提现时金额将到此微信</p>
			<input id="authorization" @click="updateOpenid" type="button" value="一键授权"/>
		</div>
		<div v-else-if="type == 2" class="form">
			<img :src="$store.state.tenantInfo.logo | fullPath"/>
			<p>授权成功，你已开通微信提现功能</p>
		</div>
		<div v-else-if="type == 3" class="form">
			<img :src="$store.state.tenantInfo.logo | fullPath"/>
			<p>分站已开通微信提现功能，授权的微信账号与当前账号不一致，是否重新授权</p>
			<input id="authorization" @click="updateOpenid" type="button" value="重新授权"/>
		</div>
    </div>
</template>

<script>
import rsa from '@/utils/rsa'
export default {
    name: '',
    data() {
        return {
            type:-1,//1未授权，2已授权（授权openid与当前openid一致），3已售罄（授权openid与当前openid不一致）
            formData:{
				username: '',
				password: '',
				verifyCode: '',
				verifySign: '',
			},
			imgUrl: '',//图片验证码路径
			code: '',
			openid:'',//当前openid
        }
    },
    created(){
		this.reflashCode();
		var openid = this.$route.query.openid;
		if(openid){
			this.openid = openid;
		}
		var token = localStorage.getItem(`dftToken`);
		if(openid && token){
			this.decideOpenid();
		}
    },
    mounted(){},
    watch: {},
    methods: {
        //-----------获取图片验证码------------
		reflashCode: function(){
			var _this = this;
		 	_this.$axios.get("/public/verifyCode",{responseType:'blob'}).then(function(res){
				 _this.imgUrl = window.URL.createObjectURL(res.data);
		 		_this.formData.verifySign = res.headers.sign;
		 	}).catch(function(error){
		 		//consloe.log(error);
		 	})
		},
		//-------登录--------
		login: function() {
			var _this = this;
			if(_this.formData.username==''){
				_this.$message.warning('账户名不能为空')
				return;
			};
			if(_this.formData.password==''){
				_this.$message.warning('密码不能为空');
				return;
			};
			if(_this.formData.verifyCode==''){
				_this.$message.warning('验证码不能为空');
				return;
			};
            // let postData = _this.$qs.stringify(_this.formData);
            _this.$request.post({
				url:'bus/info/public/authority',
				params:{
					account: rsa.encryptByPublicKey(_this.formData.username),
					password: rsa.encryptByPublicKey(_this.formData.password),
					verifyCode: _this.formData.verifyCode,
					verifySign: _this.formData.verifySign
				},
				success:res => {
					_this.type = 1
					localStorage.setItem('dftToken',res.token)
					localStorage.setItem('phone',res.info.phone);
					this.$store.commit('getUserInfo',res)
					this.$store.commit('setIsLogin',true)
					this.toWechat();
				}
            })
		},
		updateOpenid(){
			this.$request.post({
				url: 'bus/info/updateOpenid',
				params: {
					openid:this.openid
				},
				success: result =>{
					this.$message.success('授权成功');
					this.type = 2;
				},
				finally: ()=>{
				}
			})
		},
		decideOpenid(){
			this.$request.post({
				url: 'bus/info/decideOpenid',
				params: {
					openid:this.openid
				},
				success: result =>{
					this.type = result
				},
				finally: ()=>{
				}
			})
		},
		toWechat(){
			window.location= window.location.protocol+"//"+window.location.host+"/newApi/wechat/autho/public/send?type=2";
		},
    }
}
</script>

<style>
html,body{
	width: 100%;
	height: 100%;
	min-width: auto;
}
input[type='button']{
	-webkit-appearance: none;
	appearance: none;
}
</style>

<style scoped>
#wxLogin{
	width: 100%;
	height: 100vh;
	background-color: #f5f5f5;
}
.form{
	padding-top: 100px;
}
.form>img{
	width: 40%;
	display: block;
	margin: 0 auto;
	margin-bottom: 20px;
}
.form>input[type='tel'],.form>input[type='password']{
	width: 80%;
	height: 40px;
	display: block;
	margin: 0 auto;
	margin-top: 20px;
	border: none;
	border-radius: 50px;
	background-color: #FFFFFF;
	padding: 0 20px;
	box-sizing: border-box;
	font-size: 16px;
}
.form .verification{
	width: 80%;
	height: 40px;
	margin: 0 auto;
	margin-top: 20px;
	display: flex;
	justify-content: space-between;
}
.form .verification input{
	background: #FFFFFF;
	border: none;
	border-radius: 50px;
	width: 60%;
	height: 100%;
	padding: 0 20px;
	box-sizing: border-box;
	font-size: 16px;
}
.form .verification #imgcode{
	width: 38%;
	height: 100%;
	padding-right: 10px;
	cursor: pointer;
	margin-left: 10px;
}
.form .verification #imgcode img{
	width: 100%;
	height: 100%;
	display: block;
	border-radius: 5px;
}
.form input[type = 'button']{
	width: 80%;
	height: 40px;
	display: block;
	margin: 0 auto;
	margin-top: 20px;
	background-color: #ff8800;
	border: none;
	border-radius: 50px;
	color: #FFFFFF;
	font-size: 18px;
	letter-spacing: 20px;
	padding-left: 20px;
	box-sizing: border-box;
}
.form input[type = 'button']#authorization{
	letter-spacing: 2px;
	padding-left: 2px;
	box-sizing: border-box;
	width: 60%;
}
.form h3{
	font-size: 16px;
	color: #4D4D4D;
	margin-top: 10px;
	text-align: center;
}
.form p{
	text-align: center;
	color: #808080;
	font-size: 14px;	
	margin-top: 10px;
	margin-bottom: 10px;
}
</style>